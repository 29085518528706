export const environment = {
  name: 'development',
  version: 'develop',
  production: false,
  useEmulators: false,
  sentry: {
    dsn: 'https://a9aa9d80e988394f6501db85fa2c9d55@o4506207716114432.ingest.us.sentry.io/4507981510606848',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    showDialog: true,
  },
  firebase: {
    region: 'europe-west1',
    projectId: 'freddy-931ae',
    appId: '1:876816755465:web:798547ba403b1dcaae01d8',
    storageBucket: 'freddy-931ae.appspot.com',
    apiKey: 'AIzaSyAuyxHTOLb4KTVHvAVzjK5agcMFmkYtGlI',
    authDomain: 'dev-admin.freddy.ovh',
    messagingSenderId: '876816755465',
    databaseURL:
      'https://freddy-931ae-default-rtdb.europe-west1.firebasedatabase.app/',
    measurementId: 'G-06WZWQL0N7',
    recaptcha: '6LcIzlcpAAAAAJ40uUgBrYoHcRjn2QJTY8wr_V1Q',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNsZTRvenBxbzA1M3k0MXFkb2ZzM2NxbnYifQ.9Mac1d54adqHiqTp000cHg',
    geocoder: {
      countriesActivated: 'fr,nl,be,de',
    },
  },
  typesense: {
    apiKey: 'freddy-admin',
    host: 'search.freddy.ovh',
    port: 443,
    protocol: 'https',
    scenariosCollectionName: 'scenarios-dev',
    gamesCollectionName: 'games-dev',
  },
  game: {
    defaultRadius: 15,
    defaultPoints: 10,
  },
};
