import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { GuidUtils } from '../../../../core/utils/guid.utils';
import { map, startWith, tap } from 'rxjs/operators';
import { Observe } from 'common';

export interface ComboboxItem<T = any> {
  id: string;
  label: string;
  item: T;
}

@Component({
  selector: 'app-combobox-input',
  templateUrl: './combobox-input.component.html',
  styleUrls: ['./combobox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxInputComponent),
      multi: true,
    },
  ],
})
export class ComboboxInputComponent implements ControlValueAccessor, OnInit {
  constructor(private elRef: ElementRef) {}

  @Output() filterEvent = new EventEmitter<string>();
  @Input() canAddItem = false;
  @Observe('canAddItem') canAddItemInput$!: Observable<boolean | undefined>;
  @Input() addItemLabel = 'Add item';
  @Output() addItemEvent = new EventEmitter<ComboboxItem<string>>();
  newItem: boolean | null = false;
  selected?: ComboboxItem | null;
  comboboxForm = new FormControl();
  canAddItem$ = combineLatest([
    this.canAddItemInput$.pipe(startWith(false)),
    this.comboboxForm.valueChanges.pipe(startWith(this.comboboxForm.value)),
  ]).pipe(
    map(
      ([canAddItemInput, formValue]) =>
        canAddItemInput && formValue?.length > 3,
    ),
  );
  active: ComboboxItem | null = null;
  showDropdown$ = new BehaviorSubject(false);
  isDisabled = false;
  @Input() items: ComboboxItem[] | null = [];
  @Observe('items') items$!: Observable<ComboboxItem[] | null>;
  filteredItems$ = combineLatest([
    this.items$,
    this.comboboxForm.valueChanges.pipe(startWith(this.comboboxForm.value)),
  ]).pipe(
    map(([items, value]) => {
      if (!items) {
        return [];
      }
      return items.filter((item) =>
        item.label.toLowerCase().includes(value?.toLowerCase() ?? ''),
      );
    }),
  );

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.showDropdown$.next(false);
    }
  }

  ngOnInit(): void {
    this.comboboxForm.valueChanges
      .pipe(
        tap((value) => {
          this.filterEvent.emit(value);
        }),
      )
      .subscribe();
  }

  onChange = (item: ComboboxItem | null) => {};
  onTouched = () => {};

  registerOnChange(onChange: (item: ComboboxItem | null) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(item: ComboboxItem): void {
    this.selected = item;
    this.comboboxForm.setValue(this.selected?.label, { emitEvent: false });
  }

  toggle(): void {
    this.showDropdown$.next(!this.showDropdown$.value);
  }

  select(item: ComboboxItem | null): void {
    if (item === this.selected) {
      this.selected = null;
      this.comboboxForm.setValue(null, { emitEvent: false });
    } else {
      this.selected = item;
      this.comboboxForm.setValue(this.selected?.label, { emitEvent: false });
    }
    this.showDropdown$.next(false);
    this.onChange(this.selected);
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.active = null;
      this.toggleListDisplay(0);
    } else if (event.key === 'Enter') {
      this.select(this.active);
    }

    if (event.key === 'ArrowDown') {
      this.toggleListDisplay(1);
      this.setActiveNext();
    } else if (event.key === 'ArrowUp') {
      this.toggleListDisplay(1);
      this.setActivePrev();
    }
  }

  setActiveNext(): void {
    this.filteredItems$
      .subscribe((filteredItems) => {
        if (!this.active) {
          this.active = filteredItems?.[0] ?? null;
        } else {
          const nextIndex =
            (this.getCurrentIndex(filteredItems) + 1) %
            (filteredItems?.length ?? 1);
          this.active = filteredItems?.[nextIndex] ?? null;
        }
        this.scrollToActive();
      })
      .unsubscribe();
  }

  setActivePrev(): void {
    this.filteredItems$
      .subscribe((filteredItems) => {
        if (!this.active) {
          this.active = filteredItems?.[filteredItems.length - 1] ?? null;
        } else {
          const prevIndex = Math.max(
            (this.getCurrentIndex(filteredItems) - 1) %
              (filteredItems?.length ?? 1),
            0,
          );
          this.active = filteredItems?.[prevIndex] ?? null;
        }
        this.scrollToActive();
      })
      .unsubscribe();
  }

  getCurrentIndex(filteredItems: ComboboxItem[]): number {
    return (
      filteredItems?.findIndex((item) => item.id === this.active?.id) ?? -1
    );
  }

  toggleListDisplay(sender: number): void {
    this.showDropdown$.next(sender === 1);
  }

  addNewItem(): void {
    const value = this.comboboxForm.value;
    const item: ComboboxItem<string> = {
      id: GuidUtils.generateUuid(),
      label: value,
      item: value,
    };
    this.select(item);
    this.addItemEvent.emit(item);
  }

  private scrollToActive(): void {
    const index = this.getCurrentIndex(this.items ?? []);
    const listItem = this.elRef.nativeElement.querySelectorAll('li')[index];
    listItem?.scrollIntoView();
  }
}
