import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  MapMouseEvent,
  Marker,
  NavigationControl,
  Popup,
  Map,
} from 'mapbox-gl';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, lastValueFrom, ReplaySubject } from 'rxjs';
import { GeoPoint } from '@freddy/common';

@Component({
  selector: 'app-markers-map-input',
  templateUrl: './markers-map-input.component.html',
  styleUrls: ['./markers-map-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkersMapInputComponent),
      multi: true,
    },
  ],
})
export class MarkersMapInputComponent
  implements ControlValueAccessor, OnDestroy
{
  ready$ = new ReplaySubject<boolean>(1);
  map: Map | undefined;
  draggableMarkers: Marker[] = [];

  @Input()
  center: GeoPoint | undefined;

  @Input()
  multiplesMarker: boolean = false;

  @Input()
  radius: number | null = 0;

  private layersId: string[] = [];

  private internalMarkers: Marker[] = [];
  private isDisabled = false;
  private _readonlyMarkers: Marker[] = [];

  @Input()
  set readonlyMarkers(geoPoint: GeoPoint[] | null) {
    if (geoPoint) this.addReadonlyMarkers(geoPoint);
  }

  onChange = (markers: GeoPoint[] | GeoPoint) => {};

  onTouch: any = () => {};

  getCenter(): [number, number] {
    return this.center
      ? [this.center.lon, this.center.lat]
      : [4.351697, 50.846557];
  }

  mapLoaded() {
    this.map?.addControl(new NavigationControl());
    this.ready$.next(true);
    this.map?.resize();
    this.map?.on('click', (event: MapMouseEvent) => {
      this.addMarker({
        lat: event.lngLat.lat,
        lon: event.lngLat.lng,
      });
    });
  }

  registerOnChange(fn: (markers: GeoPoint[] | GeoPoint) => void) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.draggableMarkers.forEach((m) => {
      m.setDraggable(isDisabled);
    });
    this.isDisabled = isDisabled;
  }

  async writeValue(markers: GeoPoint[] | GeoPoint): Promise<void> {
    await this.isReady();
    if (markers) {
      const markersToAdd = Array.isArray(markers) ? markers : [markers];
      markersToAdd.forEach(this.addMarker.bind(this));
    }
  }

  ngOnDestroy(): void {
    this.removeMarkers();
  }

  private removeMarkers() {
    this.internalMarkers.forEach((m) => {
      m.remove();
    });
  }

  private removeReadOnlyMarkers() {
    for (let i = 0; i < this._readonlyMarkers.length; i++) {
      this._readonlyMarkers[i].remove();
    }
  }

  private canAddMarker(): boolean {
    return (
      !(!this.multiplesMarker && this.draggableMarkers.length > 0) &&
      !this.isDisabled
    );
  }

  private addMarker(marker: GeoPoint) {
    const popup = new Popup({ offset: 25 }).setText(
      'Drag the marker to change the position',
    );
    if (this.map && this.canAddMarker()) {
      const m = new Marker({
        draggable: true,
      })
        .setPopup(popup)
        .setLngLat(marker)
        .addTo(this.map);

      // this.setRadiusLayer(marker);
      m.on('dragend', () => {
        this.onChange(this.getDraggableMarkers());
      });
      this.internalMarkers.push(m);
      this.draggableMarkers.push(m);
      this.onChange(this.getDraggableMarkers());
    }
  }

  private async addReadonlyMarkers(markers: GeoPoint[]) {
    await this.isReady();
    this.removeReadOnlyMarkers();
    if (this.map) {
      for (let i = 0; i < markers.length; i++) {
        this._readonlyMarkers.push(
          new Marker({
            draggable: false,
            color: 'blue',
          })
            .setLngLat(markers[i])
            .addTo(this.map),
        );
      }
    }
  }

  private getDraggableMarkers(): GeoPoint[] | GeoPoint {
    const markers = this.draggableMarkers.map((m) => ({
      lat: m.getLngLat().lat,
      lon: m.getLngLat().lng,
    }));
    if (
      Array.isArray(markers) &&
      markers.length > 0 &&
      this.multiplesMarker === false
    ) {
      return markers[0];
    }
    return markers;
  }

  private async isReady(): Promise<boolean> {
    return lastValueFrom(
      this.ready$.asObservable().pipe(first((ready) => ready)),
    );
  }
}
